import p0 from '../_admin/assets/media/product/default.svg';
import p4 from '../_admin/assets/media/product/p-4.png';
import p5 from '../_admin/assets/media/product/p-5.png';
import p6 from '../_admin/assets/media/product/p-6.png';
import p7 from '../_admin/assets/media/product/p-7.png';
import p8 from '../_admin/assets/media/product/p-8.png';
import p9 from '../_admin/assets/media/product/p-9.png';
import p10 from '../_admin/assets/media/product/p-10.png';
import p11 from '../_admin/assets/media/product/p-11.png';
import p12 from '../_admin/assets/media/product/p-12.png';
import p13 from '../_admin/assets/media/product/p-13.png';
import p14 from '../_admin/assets/media/product/p-14.png';
import p17 from '../_admin/assets/media/product/p-17.png';
import p18 from '../_admin/assets/media/product/p-18.png';
import p19 from '../_admin/assets/media/product/p-19.png';
import p20 from '../_admin/assets/media/product/p-20.png';
import p21 from '../_admin/assets/media/product/p-21.png';
import p22 from '../_admin/assets/media/product/p-22.png';
import p23 from '../_admin/assets/media/product/p-23.png';
import p24 from '../_admin/assets/media/product/p-24.png';
import p25 from '../_admin/assets/media/product/p-25.png';
import p26 from '../_admin/assets/media/product/p-26.png';
import p27 from '../_admin/assets/media/product/p-27.png';
import p28 from '../_admin/assets/media/product/p-28.png';
import p29 from '../_admin/assets/media/product/p-29.png';
import p30 from '../_admin/assets/media/product/p-30.png';
// Brands
import b1 from '../_admin/assets/media/product/b1.png';
import b2 from '../_admin/assets/media/product/b2.png';
import b3 from '../_admin/assets/media/product/b3.png';
import b4 from '../_admin/assets/media/product/b4.png';
import b5 from '../_admin/assets/media/product/b5.png';
import b6 from '../_admin/assets/media/product/b6.png';
import nutro from '../_admin/assets/media/product/nutro.png';
// Vehicle
import v1 from '../_admin/assets/media/vehicles/v1.png';
import v2 from '../_admin/assets/media/vehicles/v2.png';
import v3 from '../_admin/assets/media/vehicles/v3.png';
import v4 from '../_admin/assets/media/vehicles/v4.png';
import v5 from '../_admin/assets/media/vehicles/v5.png';
import v6 from '../_admin/assets/media/vehicles/v6.png';
import v7 from '../_admin/assets/media/vehicles/v7.png';
import v8 from '../_admin/assets/media/vehicles/v8.png';
import v9 from '../_admin/assets/media/vehicles/v9.png';
import v10 from '../_admin/assets/media/vehicles/v10.png';
import {
  Add,
  AllPermissions,
  Dashboard,
  Delete,
  DeliveryReports,
  Edit,
  FinanceReports,
  OutWardReports,
  ReturnProductReports,
  View,
} from './constants';
import {
  AllModules,
  Sellers,
  AllSeller,
  KycVerifications,
  PendingCertificate,
  PaymentToSellers,
  SellersSalesReport,
  Customers,
  AllCustomers,
  RefundsToCustomer,
  ReceivableReports,
  OrdersDelivery,
  RoutePlan,
  Master,
  Categories,
  BusinessType,
  BusinessCertificates,
  Brand,
  TechnicalDetails,
  ProductVariants,
  MasterProduct,
  Driver,
  Vehicle,
  VehicleLoadingArea,
  SellerProductZone,
  FinanceMember,
  RolesAndPermission,
  UserManagement,
  Inventory,
  GoodsWarehouse,
  LowStockLists,
  GoodsRequest,
  ReceivedDirectGood,
  CustomerServices,
  All_Products,
  PlatformFees,
  Advertising,
  ContactInquires,
  CustomNotifications,
  Settings,
  Privacy_Policy,
  TermsConditions,
  AppSettings,
} from './constants'
export const IMAGES = {
  // DUMMY ICONS/IMAGES ********************************
  // Brands
  WadsworthDistributorsLtd: require('../_admin/assets/media/brand/wadsworth-distributors-limited.png'),
  ElvenAgri: require('../_admin/assets/media/brand/elven_agri.png'),
  SuperMealsLtd: require('../_admin/assets/media/brand/super_meal.png'),
  CoolBlue: require('../_admin/assets/media/brand/cool_blue.png'),
  MacLeansBeneCIBOLtd: require('../_admin/assets/media/brand/macLeans.png'),
  Afribon: require('../_admin/assets/media/brand/afribon.png'),
  KilimoFreshFoodsAfricaLTD: require('../_admin/assets/media/brand/kilimo.png'),
  ZamzamStationerySupermarket: require('../_admin/assets/media/brand/zamzam.png'),
  NilePerchFisheriesLtd: require('../_admin/assets/media/brand/nile_perch.png'),
  OmrosFoods: require('../_admin/assets/media/brand/omros_foods.png'),
  AmazonMonstaPops: require('../_admin/assets/media/product/amazon-monsta-pops-strawberry.png'),
  AmazonRollGum: require('../_admin/assets/media/product/amazon-roll-gum.png'),
  AzamWheatFlour: require('../_admin/assets/media/product/azam-wheat-flour.png'),
  NutroWafarBiscuits: require('../_admin/assets/media/product/nutro-wafer-biscuits.png'),
  LatoMilkPowder: require('../_admin/assets/media/product/lato-milk-powder-400g.png'),
  // PERMANENT ICONS/IMAGES ********************************
  // Products
  AmazonMonstaPopsStrawberry: require('../_admin/assets/media/product/amazon-monsta-pops-strawberry.png'),
  // SVGs **permanent**
  ThreeDot: require('../_admin/assets/media/svg_uTrade/three-dot.svg').default,
  ThreeDotRounded: require('../_admin/assets/media/svg_uTrade/three-dot-round.svg'),
  verifiedBadge: require('../_admin/assets/media/svg_uTrade/verified.svg')
    .default,
  eyeBlue: require('../_admin/assets/media/svg_uTrade/eye-active.png'),
  eyeGray: require('../_admin/assets/media/svg_uTrade/eye.png'),
  SellerSVG: require('../_admin/assets/media/svg_uTrade/sellers.svg').default,
  CustomerSVG: require('../_admin/assets/media/svg_uTrade/customers.svg')
    .default,
  OrderSVG: require('../_admin/assets/media/svg_uTrade/orders.svg').default,
  PaymentSVG: require('../_admin/assets/media/svg_uTrade/payments.svg').default,
  ProductSVG: require('../_admin/assets/media/svg_uTrade/products.svg').default,
  GreenDot: require('../_admin/assets/media/svg_uTrade/green_dot.svg').default,
  YellowError: require('../_admin/assets/media/svg_uTrade/error-warning.svg')
    .default,
  PartialTruckOne:
    require('../_admin/assets/media/svg_uTrade/partial_truck_1.svg').default,
  ArrowUp: require('../_admin/assets/media/svg_uTrade/up-arrow.svg').default,
  ArrowDown: require('../_admin/assets/media/svg_uTrade/arrow-down.svg')
    .default,
  PartialTruckTwo:
    require('../_admin/assets/media/svg_uTrade/partial_truck_2.svg').default,
  downArrow: require('../_admin/assets/media/svg_uTrade/down-arrow.svg')
    .default,
  fastDelivery: require('../_admin/assets/media/svg_uTrade/fast-delivery.svg')
    .default,
};
export const BannerIMAGES = {
  BannerAd1: require('../_admin/assets/media/advertising/b1.webp'),
  BannerAd2: require('../_admin/assets/media/advertising/b2.webp'),
  BannerAd3: require('../_admin/assets/media/advertising/b3.webp'),
  BannerAd4: require('../_admin/assets/media/advertising/b4.webp'),
  BannerAd5: require('../_admin/assets/media/advertising/b5.webp'),
};
export const FoodCategory = [
  {
    name: 'Cooking Oil',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p5,
  },
  {
    name: 'Flour',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p6,
  },
  {
    name: 'Snacks',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p7,
  },
  {
    name: 'Sweets',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p8,
  },
  {
    name: 'Drinks',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p9,
  },
  {
    name: 'Other Foods',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p10,
  },
];
export const ToiletriesCategory = [
  {
    name: 'Soaps & Bars',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p11,
  },
  {
    name: 'Detergents',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p12,
  },
  {
    name: 'Liquids',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p13,
  },
  {
    name: 'Insecticides',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p29,
  },
  {
    name: 'Other Toiletries',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p30,
  },
];
export const BeautyCategory = [
  {
    name: 'Perfumes',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p14,
  },
  {
    name: 'Dental Care',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p30,
  },
  {
    name: 'Hair Care',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p21,
  },
  {
    name: 'Skin Care',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p23,
  },
  {
    name: 'Sanitary Pads',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p18,
  },
  {
    name: 'Other Beauty Products',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p17,
  },
];
export const AccessoriesCategory = [
  {
    name: 'Books',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p25,
  },
  {
    name: 'Watches',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p26,
  },
  {
    name: 'Bags',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p27,
  },
  {
    name: 'Footwear',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p28,
  },
];
export const BabyCategory = [
  {
    name: 'Baby Oils',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p21,
  },
  {
    name: 'Baby Jellies',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p23,
  },
  {
    name: 'Baby Diapers',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p22,
  },
  {
    name: 'Baby Creams',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p20,
  },
  {
    name: 'Baby Wipes',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p24,
  },
  {
    name: 'Baby Powders',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p21,
  },
  {
    name: 'Baby Soaps',
    definedBy: 0,
    options: [],
    value: 'Please add variants like 250g, 10kg, 500ml, and 5ltr.',
    image: p24,
  },
];
export const Brands = [
  {
    name: 'Elven Agri',
    image: b1,
  },
  {
    name: 'Super Meals Limited',
    image: b2,
  },
  {
    name: 'Blue Pure Drinking Water',
    image: b3,
  },
  {
    name: 'MacLeans BeneCIBO Limited',
    image: b4,
  },
  {
    name: 'Afribon',
    image: b5,
  },
  {
    name: 'Kilimo Fresh Foods Africa LTD',
    image: b6,
  },
];
export const addTechnical = [
  {
    name: '',
    value: '',
    options: [],
    inputType: 0,
  },
];
export const variants = [
  {
    name: 'None',
    value: '0',
  },
  {
    name: 'Flavour',
    value: '1',
  },
  {
    name: 'Size',
    value: '2',
  },
  {
    name: 'Colour',
    value: '3',
  },
  {
    name: 'Type',
    value: '4',
  },
];
export const flavour = [
  {
    name: 'Chocolate',
    value: '0',
    label: 'Chocolate',
  },
  {
    name: 'Orange',
    value: '1',
    label: 'Orange',
  },
  {
    name: 'Pineapple',
    value: '2',
    label: 'Pineapple',
  },
  {
    name: 'StrawBerry',
    value: '3',
    label: 'StrawBerry',
  },
  {
    name: 'Lime',
    value: '4',
    label: 'Lime',
  },
];
export const size = [
  {
    name: '75g',
    value: '0',
    label: '75g',
  },
  {
    name: '100g',
    value: '2',
    label: '100g',
  },
  {
    name: '150g',
    value: '1',
    label: '150g',
  },
  {
    name: '350g',
    value: '4',
    label: '350g',
  },
  {
    name: '500g',
    value: '3',
    label: '500g',
  },
];
export const color = [
  {
    name: 'Orange',
    value: '0',
    label: 'Orange',
  },
  {
    name: 'Pink',
    value: '2',
    label: 'Pink',
  },
  {
    name: 'Blue',
    value: '1',
    label: 'Blue',
  },
  {
    name: 'Yellow',
    value: '4',
    label: 'Yellow',
  },
  {
    name: 'Red',
    value: '3',
    label: 'Red',
  },
];
export const type = [
  {
    name: 'Vegetarians',
    value: '0',
    label: 'Vegetarians',
  },
  {
    name: 'Non Vegetarians',
    value: '1',
    label: 'Non Vegetarians',
  },
];
export const customerJSON = [
  {
    label: 'Aunali Mohamed Abdulrazak',
    value: 'Aunali Mohamed Abdulrazak',
  },
  {
    value: 'Akil Okeke',
    label: 'Akil Okeke',
  },
  {
    value: 'Kenyatta Adeyemi',
    label: 'Kenyatta Adeyemi',
  },
  {
    value: 'Njowga Achebe',
    label: 'Njowga Achebe',
  },
  {
    value: 'Annan Ohakim',
    label: 'Annan Ohakim',
  },
  {
    value: 'Nia Ayim',
    label: 'Nia Ayim',
  },
  {
    value: 'Adea Nzeogwu',
    label: 'Adea Nzeogwu',
  },
  {
    value: 'Nalla Okafor',
    label: 'Nalla Okafor',
  },
  {
    value: 'Jamani Boro',
    label: 'Jamani Boro',
  },
  {
    value: 'Amaziah Igwe',
    label: 'Amaziah Igwe',
  },
];
export const productJSON = [
  {
    name: 'Nutro Vanilla Waffer Cream Biscuit',
    value: 0,
    id: 0,
    image: nutro,
    label: (
      <>
        <img
          src={nutro}
          height={15}
          className=" me-2"
          alt=""
        />
        <span className="fs-14 fw-500 text-black ">
          Nutro Vanilla Waffer Cream Biscuit
        </span>
      </>
    ),
  },
  {
    name: 'Nutro Orange Cream Wafers - Pack of 2',
    value: 1,
    id: 1,
    image: nutro,
    label: (
      <>
        <img
          src={nutro}
          height={11}
          className=" me-2"
          alt=""
        />
        <span className="fs-14 fw-500 text-black ">
          Nutro Orange Cream Wafers-Pack of 2
        </span>
      </>
    ),
  },
  {
    name: 'Nutro Hazelnuts Wafer Cream Biscuits',
    value: 2,
    id: 2,
    image: nutro,
    label: (
      <>
        <img
          src={nutro}
          height={11}
          className=" me-2"
          alt=""
        />
        <span className="fs-14 fw-500 text-black ">
          Nutro Hazelnuts Wafer Cream Biscuits
        </span>
      </>
    ),
  },
  {
    name: 'Nutro Chocolate Cream Wafers (Pack of 2)',
    value: 3,
    id: 3,
    image: nutro,
    label: (
      <>
        <img
          src={nutro}
          height={11}
          className=" me-2"
          alt=""
        />
        <span className="fs-14 fw-500 text-black ">
          Nutro Chocolate Cream Wafers(Pack of 2)
        </span>
      </>
    ),
  },
];
export const rolePermissions = [
  {
    module: 'All Modules',
    value: AllModules,
    permissions: [
      { title: 'All', value: AllPermissions },
      { title: 'View / List', value: View },
      { title: 'Add', value: Add },
      { title: 'Edit', value: Edit },
      { title: 'Delete', value: Delete },
    ],
  },
  {
    module: 'Dashboard',
    value: Dashboard,
    permissions: [
      { title: 'All', value: AllPermissions },
      { title: 'View / List', value: View },
    ],
  },
  {
    module: 'Sellers',
    value: Sellers,
    permissions: [
      { title: 'All', value: AllPermissions },
      { title: 'View / List', value: View },
      { title: 'Add', value: Add },
      { title: 'Edit', value: Edit },
    ],
    submodules: [
      {
        module: 'All Sellers',
        value: AllSeller,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
        ],
      },
      {
        module: 'KYC Verification',
        value: KycVerifications,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          // { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
        ],
      },
      {
        module: 'Pending Certificate',
        value: PendingCertificate,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          // { title: 'Edit', value: Edit },
        ],
      },
      {
        module: 'Payment to Sellers',
        value: PaymentToSellers,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          // { title: 'Edit', value: Edit },
        ],
      },
      {
        module: 'Sales Report',
        value: SellersSalesReport,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
        ],
      },
    ],
  },
  {
    module: 'Customers',
    value: Customers,
    permissions: [
      { title: 'All', value: AllPermissions },
      { title: 'View / List', value: View },
      { title: 'Add', value: Add },
      { title: 'Edit', value: Edit },
    ],
    submodules: [
      {
        module: 'All Customers',
        value: AllCustomers,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
        ],
      },
      {
        module: 'Refunds to Customers',
        value: RefundsToCustomer,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          // { title: 'Edit', value: Edit },
        ],
      },
      {
        module: 'Receivable Reports',
        value: ReceivableReports,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          // { title: 'Edit', value: Edit },
        ],
      },
    ],
  },
  {
    module: 'Finance Reports',
    value: FinanceReports,
    permissions: [
      { title: 'All', value: AllPermissions },
      { title: 'View / List', value: View },
      { title: 'Edit', value: Edit }, 
    ],
    submodules: [
      {
        module: 'Outward Reports',
        value: OutWardReports,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
        ],
      },
      {
        module: 'Return Product Reports',
        value: ReturnProductReports,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
        ],
      },
      {
        module: 'Delivery Reports',
        value: DeliveryReports,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Edit', value: Edit }, 
        ],
      },
    ],
  },
  {
    module: 'Orders & delivery',
    value: OrdersDelivery,
    permissions: [
      { title: 'All', value: AllPermissions },
      { title: 'View / List', value: View },
      { title: 'Add', value: Add },
      { title: 'Edit', value: Edit },
      // { title: 'Delete', value: Delete },
    ],
  },
  {
    module: 'Master',
    value: Master,
    permissions: [
      { title: 'All', value: AllPermissions },
      { title: 'View / List', value: View },
      { title: 'Add', value: Add },
      { title: 'Edit', value: Edit },
      { title: 'Delete', value: Delete },
    ],
    submodules: [
      {
        module: 'Categories',
        value: Categories,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
          { title: 'Delete', value: Delete },
        ],
      },
      {
        module: 'Business types',
        value: BusinessType,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
          { title: 'Delete', value: Delete },
        ],
      },
      {
        module: 'Business certificates',
        value: BusinessCertificates,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
          { title: 'Delete', value: Delete },
        ],
      },
      {
        module: 'Brands',
        value: Brand,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
          { title: 'Delete', value: Delete },
        ],
      },
      {
        module: 'Technical details',
        value: TechnicalDetails,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
          { title: 'Delete', value: Delete },
        ],
      },
      {
        module: 'Product variants',
        value: ProductVariants,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
          { title: 'Delete', value: Delete },
        ],
      },
      {
        module: 'Master Products',
        value: MasterProduct,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
          { title: 'Delete', value: Delete },
        ],
      },
      {
        module: 'Drivers',
        value: Driver,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
          { title: 'Delete', value: Delete },
        ],
      },
      {
        module: 'Vehicles',
        value: Vehicle,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
          { title: 'Delete', value: Delete },
        ],
      },
      {
        module: 'Vehicle loading areas',
        value: VehicleLoadingArea,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
          // { title: 'Delete', value: Delete },
        ],
      },
      {
        module: 'Seller product zone',
        value: SellerProductZone,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          // { title: 'Edit', value: Edit },
          // { title: 'Delete', value: Delete },
        ],
      },
      {
        module: 'Finance members',
        value: FinanceMember,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
          // { title: 'Delete', value: Delete },
        ],
      },
      {
        module: 'Roles & Permissions',
        value: RolesAndPermission,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
          { title: 'Delete', value: Delete },
        ],
      },
      {
        module: 'User Management',
        value: UserManagement,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
          { title: 'Delete', value: Delete },
        ],
      },
    ]
  },
  {
    module: 'Inventory',
    value: Inventory,
    permissions: [
      { title: 'All', value: AllPermissions },
      { title: 'View / List', value: View },
      { title: 'Add', value: Add },
      { title: 'Edit', value: Edit },
    ],
    submodules: [
      {
        module: 'Goods in Warehouse',
        value: GoodsWarehouse,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          // { title: 'Edit', value: Edit },
        ],
      },
      {
        module: 'Low stock list',
        value: LowStockLists,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          // { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
        ],
      },
      {
        module: 'Goods requests',
        value: GoodsRequest,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
        ],
      },
      {
        module: 'Receieved direct goods',
        value: ReceivedDirectGood,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
        ],  
      },
    ]
  },
  {
    module: 'Customer service',
    value: CustomerServices,
    permissions: [
      { title: 'All', value: AllPermissions },
      { title: 'View / List', value: View },
      { title: 'Add', value: Add },
      { title: 'Edit', value: Edit },
      { title: 'Delete', value: Delete },
    ],
  },
  {
    module: 'All Products',
    value: All_Products,
    permissions: [
      { title: 'All', value: AllPermissions },
      { title: 'View / List', value: View },
      // { title: 'Add', value: Add },
      { title: 'Edit', value: Edit },
      { title: 'Delete', value: Delete },
    ],
  },
  {
    module: 'Platform fees',
    value: PlatformFees,
    permissions: [
      { title: 'All', value: AllPermissions },
      { title: 'View / List', value: View },
      // { title: 'Add', value: Add },
      { title: 'Edit', value: Edit },
      // { title: 'Delete', value: Delete },
    ],
  },
  {
    module: 'Advertising',
    value: Advertising,
    permissions: [
      { title: 'All', value: AllPermissions },
      { title: 'View / List', value: View },
      { title: 'Add', value: Add },
      // { title: 'Edit', value: Edit },
      // { title: 'Delete', value: Delete },
    ],
  },
  {
    module: 'Contact inquiries',
    value: ContactInquires,
    permissions: [
      { title: 'All', value: AllPermissions },
      { title: 'View / List', value: View },
    ],
  },
  {
    module: 'Custom Notification',
    value: CustomNotifications,
    permissions: [
      { title: 'All', value: AllPermissions },
      { title: 'View / List', value: View },
      { title: 'Add', value: Add },
    ],
  },
  {
    module: 'General settings',
    value: Settings,
    permissions: [
      { title: 'All', value: AllPermissions },
      { title: 'View / List', value: View },
      { title: 'Add', value: Add },
      { title: 'Edit', value: Edit },
    ],
    submodules: [
      {
        module: 'Privacy Policy',
        value: Privacy_Policy,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          // { title: 'Edit', value: Edit },
        ],
      },
      {
        module: 'Terms & Conditions',
        value: TermsConditions,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          { title: 'Add', value: Add },
          // { title: 'Edit', value: Edit },
        ],
      },
      {
        module: 'App Settings',
        value: AppSettings,
        permissions: [
          { title: 'All', value: AllPermissions },
          { title: 'View / List', value: View },
          // { title: 'Add', value: Add },
          { title: 'Edit', value: Edit },
        ],
      },
    ]
  },
];
export const platformFees = [
  {
    _id: '640971db581e477fd02e3d8f',
    title: 'Accessories',
    fees: '10',
    image: p4,
    categories: [
      {
        title: 'Sub Category 4',
        image: p28,
        _id: '6419b1a91c60abd3ea7e0669',
        fees: '10',
        categories: [
          {
            title: 'Group category A1',
            _id: '64290a92a080c38188d5d700',
            fees: '10',
            _createdAt: '2023-04-02T04:54:42.022Z',
            _updatedAt: '2023-04-03T06:01:22.908Z',
          },
          {
            fees: '10',
            title: 'Group Category A1',
            _id: '64290ad4a080c38188d5d736',
            _createdAt: '2023-04-02T04:55:48.711Z',
            _updatedAt: '2023-04-03T06:06:23.387Z',
          },
          {
            title: 'Group Category acessories',
            fees: '10',
            _id: '642ad9cff4ff06dfcc71b720',
            _createdAt: '2023-04-03T13:51:11.056Z',
            _updatedAt: '2023-04-06T04:03:26.284Z',
          },
          {
            title: 'Group Category A2',
            fees: '10',
            _createdAt: '2023-03-05T11:20:19.313Z',
            _updatedAt: '2023-04-06T04:22:56.759Z',
            _id: '64047af367f7039ce77e48df',
          },
        ],
        _createdAt: '2023-03-21T13:31:21.151Z',
        _updatedAt: '2023-04-17T14:14:30.919Z',
      },
      {
        title: 'Sub Category without group',
        image: p27,
        categories: [],
        fees: '10',
        _createdAt: '2023-03-12T04:57:08.640Z',
        _updatedAt: '2023-04-19T04:05:00.201Z',
        _id: '640d5ba4b1da6e389fb9762c',
      },
    ],
    _createdAt: '2023-03-09T05:42:51.040Z',
    _updatedAt: '2023-04-15T10:34:18.244Z',
    __v: 0,
  },
  {
    _id: '6403b00efb4e3474b550a5ca',
    title: 'Appliancess',
    image: p17,
    _createdAt: '2023-03-04T20:54:38.082Z',
    _updatedAt: '2023-04-06T04:21:57.537Z',
    __v: 0,
    fees: '10',
    categories: [
      {
        title: 'Sub Category A2',
        fees: '10',
        image: p14,
        _id: '64043c9d009c951545656307',
        categories: [
          {
            title: 'Group Category A1 reverted',
            _createdAt: '2023-03-05T11:20:19.312Z',
            _updatedAt: '2023-03-05T14:05:41.376Z',
            fees: '10',
            _id: '64047af367f7039ce77e48de',
          },
          {
            title: 'Group Category appliances',
            fees: '10',
            _id: '6429317ca080c38188d5decf',
            _createdAt: '2023-04-02T07:40:44.584Z',
            _updatedAt: '2023-04-03T06:07:19.614Z',
          },
          {
            title: 'Group Category A1',
            fees: '10',
            _createdAt: '2023-03-05T11:20:19.312Z',
            _updatedAt: '2023-04-07T10:07:37.189Z',
            _id: '64047af367f7039ce77e48dd',
          },
        ],
        _createdAt: '2023-03-05T06:54:21.937Z',
        _updatedAt: '2023-04-07T10:07:37.189Z',
      },
      {
        title: 'Sub Category A1',
        image: p12,
        categories: [],
        _createdAt: '2023-03-05T06:54:21.936Z',
        fees: '10',
        _updatedAt: '2023-04-07T10:07:37.189Z',
        _id: '64043c9d009c951545656306',
      },
      {
        title: 'Sub Category A3',
        image: p11,
        fees: '10',
        _id: '6427a409a080c38188d5ae7a',
        categories: [],
        _createdAt: '2023-04-01T03:24:57.251Z',
        _updatedAt: '2023-04-03T06:03:03.953Z',
      },
      {
        title: 'Stationaries',
        image: p18,
        fees: '10',
        categories: [
          {
            title: 'Group Category of Stationary',
            fees: '10',
            _createdAt: '2023-03-09T05:44:29.885Z',
            _updatedAt: '2023-03-09T05:58:53.839Z',
            _id: '6409723d581e477fd02e3da2',
          },
        ],
        _createdAt: '2023-03-09T05:43:43.702Z',
        _updatedAt: '2023-04-01T06:59:42.385Z',
        _id: '6409720f581e477fd02e3d97',
      },
      {
        title: 'Sub Category appliances',
        image: p19,
        fees: '10',
        _id: '6427ee9ca080c38188d5cac2',
        categories: [],
        _createdAt: '2023-04-01T08:43:08.665Z',
        _updatedAt: '2023-04-03T06:07:45.589Z',
      },
    ],
  },
  {
    _id: '6403b00efb4e3474b550a5cb',
    fees: '10',
    title: 'Baby Products',
    image: p24,
    _createdAt: '2023-03-04T20:54:38.082Z',
    _updatedAt: '2023-04-15T10:34:18.244Z',
    __v: 0,
    categories: [
      {
        title: 'Sub Category B1',
        image: p23,
        fees: '10',
        _id: '64043c9d009c951545656308',
        categories: [
          {
            title: 'Group Category B1',
            _id: '64047af367f7039ce77e48e0',
            _createdAt: '2023-03-05T11:20:19.313Z',
            fees: '10',
            _updatedAt: '2023-03-05T11:20:19.313Z',
          },
        ],
        _createdAt: '2023-03-05T06:54:21.939Z',
        _updatedAt: '2023-03-05T14:05:41.376Z',
      },
      {
        title: 'Sub Category B2',
        image: p22,
        fees: '10',
        _id: '64043c9d009c951545656309',
        categories: [
          {
            title: 'Group Category B2',
            fees: '10',
            _id: '64047af367f7039ce77e48e1',
            _createdAt: '2023-03-05T11:20:19.314Z',
            _updatedAt: '2023-03-05T11:20:19.314Z',
          },
        ],
        _createdAt: '2023-03-05T06:54:21.939Z',
        _updatedAt: '2023-03-05T06:54:21.939Z',
      },
    ],
  },
  {
    _id: '6407233a6b57f135520729e4',
    fees: '10',
    title: 'Beauty and Personal Care',
    image: p21,
    categories: [],
    _createdAt: '2023-03-07T11:42:50.582Z',
    _updatedAt: '2023-03-07T11:42:50.582Z',
    __v: 0,
  },
  {
    _id: '641d33c0b2d256ec508c38c8',
    fees: '10',
    title: 'Boot',
    image: p28,
    categories: [],
    _createdAt: '2023-03-24T05:23:12.322Z',
    _updatedAt: '2023-03-24T07:11:30.860Z',
    __v: 0,
  },
  {
    _id: '64254ee6a080c38188d53016',
    fees: '10',
    title: 'Electronics',
    image: p0,
    categories: [],
    _createdAt: '2023-03-30T08:57:10.808Z',
    _updatedAt: '2023-04-03T05:55:16.349Z',
    __v: 0,
  },
  {
    _id: '6427a271a080c38188d5ae29',
    fees: '10',
    title: 'Fan',
    image: p0,
    categories: [],
    _createdAt: '2023-04-01T03:18:09.321Z',
    _updatedAt: '2023-04-03T05:58:32.696Z',
    __v: 0,
  },
  {
    _id: '642299623bfcf1c19a252d9b',
    fees: '10',
    title: 'Farming',
    image: p0,
    categories: [],
    _createdAt: '2023-03-28T07:38:10.637Z',
    _updatedAt: '2023-04-03T05:59:15.395Z',
    __v: 0,
  },
  {
    _id: '640d5bb6b1da6e389fb9763a',
    fees: '10',
    title: 'Furniture',
    image: p0,
    categories: [],
    _createdAt: '2023-03-12T04:57:26.210Z',
    _updatedAt: '2023-04-03T06:05:02.489Z',
    __v: 0,
  },
  {
    _id: '6405d3aa826a95bb44043187',
    fees: '10',
    title: 'Grocery materials',
    image: p10,
    categories: [],
    _createdAt: '2023-03-06T11:51:06.944Z',
    _updatedAt: '2023-04-03T06:05:17.014Z',
    __v: 0,
  },
  {
    _id: '641d33c0b2d256ec508c38c7',
    fees: '10',
    title: 'Lamp',
    image: p17,
    categories: [],
    _createdAt: '2023-03-24T05:23:12.321Z',
    _updatedAt: '2023-03-24T05:23:12.321Z',
    __v: 0,
  },
  {
    _id: '640d5b86b1da6e389fb9761a',
    fees: '10',
    title: 'Stationary',
    image: p25,
    categories: [
      {
        title: 'Pens',
        image: p0,
        _id: '640d5c22b1da6e389fb97661',
        categories: [],
        _createdAt: '2023-03-12T04:59:14.447Z',
        _updatedAt: '2023-04-06T04:22:56.759Z',
      },
    ],
    _createdAt: '2023-03-12T04:56:38.034Z',
    _updatedAt: '2023-04-06T04:22:56.759Z',
    __v: 0,
  },
  {
    _id: '642299623bfcf1c19a252d9c',
    fees: '10',
    title: 'Toys',
    image: p7,
    categories: [],
    _createdAt: '2023-03-28T07:38:10.638Z',
    _updatedAt: '2023-04-03T05:58:02.665Z',
    __v: 0,
  },
  {
    _id: '64254ee7a080c38188d53019',
    fees: '10',
    title: 'Watches',
    image: p26,
    categories: [],
    _createdAt: '2023-03-30T08:57:11.013Z',
    _updatedAt: '2023-04-03T05:56:35.119Z',
    __v: 0,
  },
  {
    _id: '64254ee7a080c38188d5301c',
    fees: '10',
    title: 'kitchen',
    image: p13,
    categories: [
      {
        title: 'Sub Category K1',
        image: p0,
        _id: '643a81a5433a3f7d4a8bcaad',
        categories: [],
        _createdAt: '2023-04-15T10:51:17.534Z',
        _updatedAt: '2023-04-15T10:51:17.534Z',
      },
    ],
    _createdAt: '2023-03-30T08:57:11.361Z',
    _updatedAt: '2023-04-03T05:57:13.827Z',
    __v: 0,
  },
];
export const ordersJSON = [
  {
    orderDate: '25/03/23',
    orderTime: '21:30',
    orderId: '#00982',
    buyerDetails: 'Rashid Rabi Shamoon',
    quantity: '23',
    totalAmount: '237,500',
    status: 1,
    totalVehicles: '10',
    totalGoodsWeight: '9.7',
  },
  {
    orderDate: '25/03/23',
    orderTime: '15:10',
    orderId: '#00981',
    buyerDetails: 'Sulaiman Fareed',
    quantity: '12',
    totalAmount: '311,500',
    status: 2,
    totalVehicles: '13',
    totalGoodsWeight: '13.9',
  },
  {
    orderDate: '25/03/23',
    orderTime: '10:42',
    orderId: '#00980',
    buyerDetails: 'Bakri Hilmi Asghar',
    quantity: '23',
    totalAmount: '0',
    status: 3,
    totalVehicles: '15',
    totalGoodsWeight: '16',
  },
  {
    orderDate: '24/03/23',
    orderTime: '18:10',
    orderId: '#00979',
    buyerDetails: 'Mahjub Abboud',
    quantity: '27',
    totalAmount: '213,500',
    status: 4,
    totalVehicles: '9',
    totalGoodsWeight: '12.6',
  },
  {
    orderDate: '25/03/23',
    orderTime: ' 21:30',
    orderId: '#00982',
    buyerDetails: 'Jabbar Hud Handal',
    quantity: '19',
    totalAmount: '311,500',
    status: 2,
    totalVehicles: '22',
    totalGoodsWeight: '24.8',
  },
];
export const vehicles = [
  {
    vehicleImg: v1,
    driverName: 'Abdulrazak Niam',
    vehicleDetail: 'T 940 AGY',
    totalGoodsWeight: '1.0',
    loadingArea: '1',
    status: 'Not loaded',
  },
  {
    vehicleImg: v2,
    driverName: 'Nia Ayim',
    vehicleDetail: 'T 804 BBF',
    totalGoodsWeight: '1.0',
    loadingArea: '2',
    status: 'Not loaded',
  },
  {
    vehicleImg: v3,
    driverName: 'Njowga Achebe',
    vehicleDetail: 'T 804 BBF',
    totalGoodsWeight: '1.0',
    loadingArea: '3',
    status: 'Not loaded',
  },
  {
    vehicleImg: v4,
    driverName: 'Akil Okeke',
    vehicleDetail: 'T 385 BGN',
    totalGoodsWeight: '0.7',
    loadingArea: '4',
    status: 'Not loaded',
  },
  {
    vehicleImg: v5,
    driverName: 'Annan Ohakim',
    vehicleDetail: 'T 103 ABE',
    totalGoodsWeight: '1.0',
    loadingArea: '',
    status: 'Not loaded',
  },
  {
    vehicleImg: v6,
    driverName: 'Matthew Powell',
    vehicleDetail: 'T 940 AGY',
    totalGoodsWeight: '1.0',
    loadingArea: '',
    status: 'Not loaded',
  },
  {
    vehicleImg: v7,
    driverName: 'Barbara Mendoza',
    vehicleDetail: 'T 332 ANN',
    totalGoodsWeight: '1.0',
    loadingArea: '',
    status: 'Not loaded',
  },
  {
    vehicleImg: v8,
    driverName: 'Jamani Boro',
    vehicleDetail: 'T 895 AHE',
    totalGoodsWeight: '1.0',
    loadingArea: '',
    status: 'Not loaded',
  },
  {
    vehicleImg: v9,
    driverName: 'Amaziah Igwe',
    vehicleDetail: 'T 895 AHE',
    totalGoodsWeight: '1.0',
    loadingArea: '',
    status: 'Not loaded',
  },
  {
    vehicleImg: v10,
    driverName: 'Kenyatta Adeyemi',
    vehicleDetail: 'T 772 BBE',
    totalGoodsWeight: '1.0',
    loadingArea: '',
    status: 'Not loaded',
  },
];
export const BrandAndTotalSales = [
  {
    brandImage: IMAGES.WadsworthDistributorsLtd,
    brandName: 'Wadsworth Distributors Limited',
    totalSales: '10,555,500',
    commissionEarned: '1,794,435',
    paymentsCompleted: '7,236,500',
    paymentsDue: '3,319,000',
    totalOrders: '56',
    totalSale: '5.85M',
  },
  {
    brandImage: IMAGES.ElvenAgri,
    brandName: 'Elven Agri',
    totalSales: '9,513,214',
    commissionEarned: '1,426,982',
    paymentsCompleted: '5,520,047',
    paymentsDue: '3,993,167',
    totalOrders: '43',
    totalSale: '4.49M',
  },
  {
    brandImage: IMAGES.SuperMealsLtd,
    brandName: 'Super Meals Limited',
    totalSales: '7,767,500',
    commissionEarned: '932,100',
    paymentsCompleted: '5,500,531',
    paymentsDue: '2,266,969',
    totalOrders: '37',
    totalSale: '3.75M',
  },
  {
    brandImage: IMAGES.CoolBlue,
    brandName: 'Cool Blue',
    totalSales: '5,450,384',
    commissionEarned: '545,038',
    paymentsCompleted: '5,230,072',
    paymentsDue: '220,312',
    totalOrders: '32',
    totalSale: '2.65M',
  },
  {
    brandImage: IMAGES.MacLeansBeneCIBOLtd,
    brandName: 'MacLeans BeneCIBO Limited',
    totalSales: '2,050,020',
    commissionEarned: '266,502',
    paymentsCompleted: '1,750,047',
    paymentsDue: '299,973',
    totalOrders: '30',
    totalSale: '2.42M',
  },
  {
    brandImage: IMAGES.Afribon,
    brandName: 'Afribon',
    totalSales: '1,137,982',
    commissionEarned: '91,038',
    paymentsCompleted: '955,321',
    paymentsDue: '182,661',
    totalOrders: '28',
    totalSale: '2.29M',
  },
  {
    brandImage: IMAGES.KilimoFreshFoodsAfricaLTD,
    brandName: 'Kilimo Fresh Foods Africa LTD',
    totalSales: '982,217',
    commissionEarned: '88,399',
    paymentsCompleted: '0',
    paymentsDue: '982,217',
    totalOrders: '43',
    totalSale: '2.00M',
  },
  {
    brandImage: IMAGES.ZamzamStationerySupermarket,
    brandName: 'Zamzam Stationery Supermarket',
    totalSales: '750,490',
    commissionEarned: '112,573',
    paymentsCompleted: '45,212',
    paymentsDue: '45,212',
    totalOrders: '30',
    totalSale: '1.98M',
  },
  {
    brandImage: IMAGES.NilePerchFisheriesLtd,
    brandName: 'Nile Perch Fisheries Ltd',
    totalSales: '600,129',
    commissionEarned: '42,009',
    paymentsCompleted: '720,350',
    paymentsDue: '30,140',
    totalOrders: '15',
    totalSale: '1.67M',
  },
  {
    brandImage: IMAGES.OmrosFoods,
    brandName: 'Omros Foods',
    totalSales: '550,948',
    commissionEarned: '55,094',
    paymentsCompleted: '503,897',
    paymentsDue: '47,051',
    totalOrders: '08',
    totalSale: '1.65M',
  },
];
